.home-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    font-size: 48px;

    .section {
        flex: 1;

        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: black;
        transition: background-color 0.3s ease, color 0.3s ease;

        .section-link {
            margin-bottom: 5px;

            .section-link-underline {
                height: 8px;
                border-radius: 4px;
                transition: background-color 0.3s ease;
            }
        }

        &:hover {
            color: white;

            .section-link-underline {
                background-color: white;
            }
        }
    }

    .about-section {
        background-color: var(--secondary-about);
        color: black;
        transition: background-color 0.3s ease, color 0.3s ease;

        .section-link-underline {
            background-color: var(--primary-about);
        }

        &:hover {
            background-color: var(--primary-about);
        }
    }

    .experience-section {
        background-color: var(--secondary-experience);
        color: black;
        transition: background-color 0.3s ease, color 0.3s ease;

        .section-link-underline {
            background-color: var(--primary-experience);
        }

        &:hover {
            background-color: var(--primary-experience);
        }
    }

    .contact-section {
        background-color: var(--secondary-contact);
        color: black;
        transition: background-color 0.3s ease, color 0.3s ease;

        .section-link-underline {
            background-color: var(--primary-contact);
        }

        &:hover {
            background-color: var(--primary-contact);
        }
    }
}

@media (max-width: 768px) {
    .home-container {
        flex-direction: column;
    }
}