.page-container {

    .page-header {
        
        .page-header-underline-experience {
            background-color: var(--primary-experience);
        }
    }

    .page-content {
        .image-shadow-experience {
            background-color: #eee;
            box-shadow: 10px 10px 0 var(--secondary-experience);
        }
    }
}