.page-container {

    .page-header {
        
        .page-header-underline-contact {
            background-color: var(--primary-contact);
        }
    }

    .page-content-contact {

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        /* display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 25px;

        >div {
            flex: 1;
        }

        >div:first-child {
            min-width: 425px;
            flex: 3;
        } */
    }

    .page-section-contact {
        margin-bottom: 0 !important;
    }
}