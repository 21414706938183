.skill-container {
    display: inline-block;
    border: 1.5px solid black;
    border-radius: 10000px;
    padding: 3px 9px 3px 9px;
}

.skill-container-frontend {
    border: 1.5px solid var(--primary-skill-frontend);
    background-color: var(--secondary-skill-frontend);
}

.skill-container-backend {
    border: 1.5px solid var(--primary-skill-backend);
    background-color: var(--secondary-skill-backend);
}

.skill-container-database {
    border: 1.5px solid var(--primary-skill-database);
    background-color: var(--secondary-skill-database);
}

.skill-container-cloud {
    border: 1.5px solid var(--primary-skill-cloud);
    background-color: var(--secondary-skill-cloud);
}

.skill-container-tools {
    border: 1.5px solid var(--primary-skill-tools);
    background-color: var(--secondary-skill-tools);
}

.skill-container-soft {
    border: 1.5px solid var(--primary-skill-soft);
    background-color: var(--secondary-skill-soft);
}