.page-container {

    .page-header {
        
        .page-header-underline-about {
            background-color: var(--primary-about);
        }
    }

    .page-content {
        .image-shadow-about {
            box-shadow: 10px 10px 0 var(--secondary-about);
        }
    }
}