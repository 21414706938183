.project-card {
    .project-card-header {
        display: flex;
        justify-content: space-between;

        .project-card-header-date {
            color: var(--secondary-text);
        }
    }

    .project-card-blurb {
        margin-top: 18px;
    }

    .project-card-actions {
        display: flex;
        justify-content: space-between;
        margin-left: 8px;

        .project-card-actions-skills {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            margin-bottom: 7px;
        }

        .project-card-actions-expand {
            margin-top: auto;
        }
    }

    .project-card-description {
        background-color: #f8f8f8;
    }
}