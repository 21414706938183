.work-card {
    .work-card-header {
        display: flex;
        justify-content: space-between;

        .work-card-header-date {
            color: var(--secondary-text);
        }
    }

    .work-card-blurb {
        color: var(--secondary-text);
        font-style: italic;
    }

    .work-card-actions {
        display: flex;
        justify-content: space-between;
        margin-left: 8px;

        .work-card-actions-skills {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            margin-bottom: 7px;
        }

        .work-card-actions-expand {
            margin-top: auto;
        }
    }

    .work-card-description {
        background-color: #f8f8f8;
        
        p:first-child {
            margin-top: 10px;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }
}