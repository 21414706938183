.page-section-header {
    font-size: 32px;
    display: inline-block;

    .page-section-header-text {
        margin-left: 2px;
        margin-right: 2px;
    }

    .page-section-header-underline {
        
        height: 10px;
        border-radius: 5px;
    }

    .page-section-header-underline-about {
        background-color: var(--primary-about);
    }
    .page-section-header-underline-experience {
        background-color: var(--primary-experience);
    }
    .page-section-header-underline-contact {
        background-color: var(--primary-contact);
    }
}