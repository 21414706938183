.page-container {
    max-width: 950px;
    margin: 0 auto;
    padding: 30px;

    a {
        /* color: #13a1ff; */
    }

    .page-header {
        margin-top: 80px;
        margin-bottom: 50px;

        .page-header-text {
            margin-left: 4px;
            font-size: 70px;
        }

        .page-header-underline {
            height: 24px;
            width: auto;
            border-radius: 12px;
        }
    }

    .page-content {
        margin-top: 20px;
        font-size: 20px;

        margin-bottom: 80px;

        .page-section {
            margin-bottom: 50px;


            .image-left {
                float: left;
                margin: 0 30px 20px 0;
            }

            .image-right {
                float: right;
                margin: 0 10px 20px 20px;
            }

            .page-skills {
                >div {
                    display: inline-block;
                    margin-right: 5px;
                    margin-bottom: 5px;
                }

                margin-bottom: 10px;

                .page-skills-area {
                    margin-right: 10px;
                }
            }

            .page-work {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 15px;

                > div {
                    max-width: 700px;
                }
            }

            .page-projects {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 15px;

                > div {
                    max-width: 450px;
                }
            }

            .page-list-item {
                >div {
                    display: inline-block;
                }

                >div:first-child {
                    margin-right: 6px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .image-left, .image-right {
        display: block !important;
        margin: 0 auto 0 auto !important;
        float: none !important;
        
    }
}