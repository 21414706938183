.navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 55px;
    box-shadow: 0 6px 6px rgba(0,0,0,.06);
    gap: 7px;
    /* padding: 0 12px 0 12px; */
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 1000;

    /* give the first and last divs under this class a left/right margin of 12px */
    >div:first-child {
        margin-left: 12px;
    }

    >div:last-child {
        margin-right: 12px;
    }

    .home-section {
        flex: 1;

        display: flex;
        align-items: center;

        .link-text {

            color: #888888;
            text-decoration: none;
        }
    }
    .sections-section {
        display: flex;
        flex-direction: row;
        text-align: center;
        padding: 2px;

        .section {
            display: grid;
            width: 105px;
            padding: 2px;
            color: black;
            transition: color 0.3s ease;

            .rounded-section {
                border-radius: 5px;
                padding: 4px 4px 5.5px 4px;
                background-color: white;
                transition: background-color 0.3s ease;

                .section-link {
                    font-size: 18px;
                    display: inline-block;
                    margin-top: 3px;

                    .section-link-underline {
                        height: 6px;
                        border-radius: 3px;
                    }
                }
            }

            .rounded-about {
                .section-link-underline {
                    background-color: var(--primary-about);
                }

                &:hover {
                    background-color: var(--secondary-about);
                }
            }
            .rounded-experience {
                .section-link-underline {
                    background-color: var(--primary-experience);
                }

                &:hover {
                    background-color: var(--secondary-experience);
                }
            }
            .rounded-contact {
                .section-link-underline {
                    background-color: var(--primary-contact);
                }

                &:hover {
                    background-color: var(--secondary-contact);
                }
            }
        }
    }
    .links-section {
        flex: 1;

        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
    }
}

@media (max-width: 768px) {
    .home-section, .links-section {
        display: none !important;
    }
}